<template>
    <div class="container">
    <el-button @click="openadd">新 增</el-button>
        <auto-table :DataList="Data" :option="option" :total="total">
            <el-table-column label="操作">
            <template slot-scope="{ row }">
                <el-button size="small" type="primary" @click="openDia(row)">修改</el-button>
            </template>
          </el-table-column>
        </auto-table>

        <el-dialog
  :visible.sync="dialogVisible"
  width="30%">
  <el-form >
    <el-form-item label="类型" v-if="mode == 'add'">
    <el-input v-model="type" placeholder="数据"></el-input>
  </el-form-item>
  <el-form-item label="数据">
    <el-input v-model="value" placeholder="数据"></el-input>
  </el-form-item>
  <el-form-item label="说明">
    <el-input v-model="remark" placeholder="说明"></el-input>
  </el-form-item>
</el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="add" v-if="mode == 'add'">确 定</el-button>
    <el-button type="primary" @click="update" v-else>确 定</el-button>
  </span>
</el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible:false,
            curData:{},
            Data: [],
            option: [{name:'类型',value:'param_key'},{name:'数据',value:'param_value'},{name:'说明',value:'remark'}],
            total: 0,
            value:'',
            remark:'',
            type:'',
            mode:"add"
        };
    },

    mounted() {
        this.getList()
    },

    methods: {
        openadd(){
            this.value = ''
            this.remark = ''
            this.type =""
            this.mode = 'add'
           this.dialogVisible = true
        },
        openDia(row){
            this.mode = 'edit'
            this.curData = row
            this.value = row.param_value
            this.remark = row.remark
           this.dialogVisible = true
        },
        getList() {
            this.$axios({
                url: 'user/sysParam/queryManagerListPage'
            }).then(res => {
                this.Data = res.data.data.rows
                this.total = res.data.data.total
            })
        },
        update() {
            this.$axios({
                url: 'user/sysParam/update',
                method:'post',
                data:{
                    param_value:this.value,
                    param_key:this.curData.param_key,
                    remark:this.remark
                }
            }).then(res => {
                this.$message(res.data.message)
                this.dialogVisible = false
                this.getList()
            })
        },
        add() {
            this.$axios({
                url: 'user/sysParam/insert',
                method:'post',
                data:{
                    param_value:this.value,
                    param_key:this.type,
                    remark:this.remark
                }
            }).then(res => {
                this.$message(res.data.message)
                this.dialogVisible = false
                this.getList()
            })
        },
    },
};
</script>

<style lang="less" scoped></style>